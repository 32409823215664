import { Box } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import CustomTabsTable from "../global/CustomTabsTable";

const CNTExchangesTable = ({ dataTable }) => {
  const [currentTab, setCurrentTab] = useState("exchanges");

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 170,
      renderCell: (params) => {
        return (
          <Box
            key={params.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={params.row.logo}
              alt="logo"
              style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
            <Box>
              <Link
                to={`/entities/${params.row.slug}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {params.formattedValue}
              </Link>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "volumes",
      headerName: "Volume",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueGetter: (value) => {
        value = value.value;
        if (value && value.length > 0) {
          let mostRecent = value.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          )[0];
          return mostRecent.volume;
        }
        return 0;
      },
      valueFormatter: (value) => {
        return value.value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        });
      },
      width: 120,
    },
    {
      field: "hasNativeAssets",
      headerName: "Native Assets",
      type: "boolean",
      flex: 1,
    },
    {
      field: "isCentralized",
      headerName: "Centralized",
      type: "boolean",
      flex: 1,
    },
  ];

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);
  };

  const tabsFunctions = {
    exchanges: {
      func: function (data) {
        return data;
      },
      label: "Exchanges",
      color: "default",
      sortModel: [
        {
          field: "volumes",
          sort: "desc",
        },
      ],
    },
  };

  return (
    <CustomTabsTable
      data={dataTable}
      columns={columns}
      name={"AssetsExchanges"}
      tabs={tabsFunctions}
      defaultValue={"exchanges"}
      sortModel={tabsFunctions.exchanges.sortModel}
      onTabChange={handleTabChange}
      sxTable={{
        marginTop: "0px",
        height: "68vh",
      }}
    />
  );
};

export default CNTExchangesTable;
