import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { colorGrid } from "../../theme";
import { useUserData } from "../../user";
import { useExchangeStatus } from "../../utils/contexts/entities/exchanges/exchangeStatusContext";
import {
  formatAndSortVolumesForLineChart,
  getLatestVolume,
  getTradingPairs,
} from "../../utils/entity/entity";
import { formatDate } from "../../utils/global";
import { fillEmptyDates } from "../../utils/graphs/graphs";
import CustomButton from "../global/CustomButton";
import CustomCard from "../global/CustomCard";
import LineChart from "../global/LineChart";
import TruncateWords from "../global/TruncatedWords";

const defaultStatusChartConfig = {
  series: [
    {
      name: "UP",
      data: [],
    },
    {
      name: "DOWN",
      data: [],
    },
  ],
  options: {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    // title: { text: "Deposits" },
    subtitle: { text: "90% Uptime" },
    colors: ["#22c55e9c", "#ff56309c"],

    dataLabels: {
      enabled: true,
      style: {
        fontSize: ".4rem",
        colors: ["#0f8d57", "#b71d19"],
      },
      formatter: function (val, opts) {
        if (val.toFixed(0) < 50) {
          return "";
        }
        return val.toFixed(0) + "%";
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: "dd MMM",
        formatter: undefined,
      },
      y: {
        formatter: (seriesName) => `${(seriesName / 60).toFixed(2)} hours`,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      categories: [],
    },
  },
};

const cardContentStyle = {
  height: "500px",
};

function createChartData(entity) {
  const chartData = [];
  if (entity.baseVolumes.length > 0) {
    const dataBase = formatAndSortVolumesForLineChart(entity, "base");
    chartData.push({
      id: "baseVolume",
      data: dataBase.map((dataItem) => ({
        y: dataItem.y,
        x: dataItem.formattedDate,
      })),
      visible: "true",
      color: "#f2c6b4",
      name: "Base",
    });
  }

  if (entity.targetVolumes.length > 0) {
    const dataTarget = formatAndSortVolumesForLineChart(entity, "target");
    chartData.push({
      id: "targetVolume",
      data: dataTarget.map((dataItem) => ({
        y: dataItem.y,
        x: dataItem.formattedDate,
      })),
      visible: "true",
      color: "#a8d5e2",
      name: "Target",
    });
  }
  return chartData;
}

const ExchangeDetails = ({ entityInit, onEdit, exchangeInfo }) => {
  const [entity, setEntity] = useState(entityInit);
  const dataLineChart = fillEmptyDates(createChartData(entity));
  const userData = useUserData();
  const {
    globalUpdate: exchangeDEWISUpdate,
    exchangeUpdate,
    joinExchangeRoom,
    leaveExchangeRoom,
  } = useExchangeStatus();
  const [withdrawalStatusChart, setWithdrawalStatusChart] = useState(
    defaultStatusChartConfig
  );
  const [depositStatusChart, setDepositStatusChart] = useState(
    defaultStatusChartConfig
  );

  const initialDepositStatus = entity?.exchangeStatus
    ? entity?.exchangeStatus?.depositAllowed
      ? "Online"
      : "Offline"
    : "No Data";
  const initialWithdrawalStatus = entity?.exchangeStatus
    ? entity?.exchangeStatus?.withdrawalAllowed
      ? "Online"
      : "Offline"
    : "No Data";

  const initialFailScore = entity?.exchangeStatus
    ? entity?.exchangeStatus?.failScore
    : 0;

  const [currentDepositStatus, setCurrentDepositStatus] =
    useState(initialDepositStatus);
  const [currentWithdrawalStatus, setCurrentWithdrawalStatus] = useState(
    initialWithdrawalStatus
  );
  const [currentFailScore, setCurrentFailScore] = useState(initialFailScore);

  function handleEdit(mode) {
    onEdit(mode);
  }

  useEffect(() => {
    joinExchangeRoom(entity.slug);

    return () => {
      leaveExchangeRoom(entity.slug);
    };
  }, [entity.slug, joinExchangeRoom, leaveExchangeRoom]);

  useEffect(() => {
    if (
      exchangeUpdate &&
      entity.slug === exchangeUpdate.exchangeSlug &&
      exchangeUpdate.failScore >= 0
    ) {
      setCurrentFailScore(exchangeUpdate.failScore);
    }
  }, [exchangeUpdate]);

  useEffect(() => {
    if (
      exchangeDEWISUpdate &&
      exchangeDEWISUpdate.statusUpdate &&
      entity.slug === exchangeDEWISUpdate.exchangeSlug
    ) {
      setCurrentDepositStatus(
        exchangeDEWISUpdate.statusUpdate.depositAllowed ? "Online" : "Offline"
      );
      setCurrentWithdrawalStatus(
        exchangeDEWISUpdate.statusUpdate.withdrawalAllowed
          ? "Online"
          : "Offline"
      );
      setEntity((prevValue) => {
        return {
          ...prevValue,
          exchangeStatus: {
            ...prevValue.exchangeStatus,
            statusChanges: [
              ...(prevValue.exchangeStatus?.statusChanges || []),
              ...exchangeDEWISUpdate.changedDEWIs,
            ],
          },
        };
      });
    }
  }, [exchangeDEWISUpdate]);

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getUTCFullYear() === today.getUTCFullYear() &&
      someDate.getUTCMonth() === today.getUTCMonth() &&
      someDate.getUTCDate() === today.getUTCDate()
    );
  };

  useEffect(() => {
    if (entity?.exchangeStatus && entity.exchangeStatus?.statusChanges) {
      const dataForStatusChange = {
        withdrawal: {},
        deposit: {},
      };
      const statusChanges = entity.exchangeStatus?.statusChanges.sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );

      if (!statusChanges.length > 0) {
        const thirtyDaysAgoStartOfDayUTC = new Date();
        thirtyDaysAgoStartOfDayUTC.setUTCDate(
          thirtyDaysAgoStartOfDayUTC.getUTCDate() - 30
        );
        thirtyDaysAgoStartOfDayUTC.setUTCHours(0, 0, 0, 0);

        const timestamp = thirtyDaysAgoStartOfDayUTC.toISOString();
        statusChanges.push(
          {
            status: entity?.exchangeStatus.depositAllowed ? "up" : "down",
            timestamp: timestamp,
            type: "deposit",
          },
          {
            status: entity?.exchangeStatus.withdrawalAllowed ? "up" : "down",
            timestamp: timestamp,
            type: "withdrawal",
          }
        );
      }

      const firstDate = new Date(statusChanges[0].timestamp);
      const lastDate = new Date();
      lastDate.setHours(23, 59, 59, 999);

      for (
        let date = new Date(firstDate);
        date < lastDate;
        date.setDate(date.getDate() + 1)
      ) {
        const day = formatDate(date);
        dataForStatusChange.deposit[day] = {
          up: 0,
          down: 0,
          lastStatus: null,
        };
        dataForStatusChange.withdrawal[day] = {
          up: 0,
          down: 0,
          lastStatus: null,
        };
      }

      statusChanges.forEach((statusChange) => {
        const statusDate = new Date(statusChange.timestamp);
        if (isToday(statusDate)) {
          return;
        }
        const day = formatDate(statusDate);
        const statusType = statusChange.type;
        const status = statusChange.status;
        const hours = statusDate.getUTCHours();
        const minutes = statusDate.getUTCMinutes();
        if (dataForStatusChange[statusType][day].lastStatus == null) {
          dataForStatusChange[statusType][day][
            status === "down" ? "up" : "down"
          ] = 60 * hours + minutes;
          dataForStatusChange[statusType][day].lastStatus = {
            date: statusDate,
            status: status,
          };
        } else {
          const oldStatusDate =
            dataForStatusChange[statusType][day].lastStatus.date;
          const oldStatus =
            dataForStatusChange[statusType][day].lastStatus.status;

          const dateDifference = statusDate - oldStatusDate;
          const minutesDifference = dateDifference / (1000 * 60);
          dataForStatusChange[statusType][day][oldStatus] += minutesDifference;
          dataForStatusChange[statusType][day].lastStatus = {
            date: statusDate,
            status: status,
          };
        }
      });

      let lastDepStatus;
      let lastWithStatus;

      let depositSortedData = {
        series: [
          {
            name: "UP",
            data: [],
          },
          {
            name: "DOWN",
            data: [],
          },
        ],
        categories: [],
      };

      let withdrawalSortedData = {
        series: [
          {
            name: "UP",
            data: [],
          },
          {
            name: "DOWN",
            data: [],
          },
        ],
        categories: [],
      };

      Object.keys(dataForStatusChange.deposit).forEach((depositDate) => {
        if (dataForStatusChange.deposit[depositDate].lastStatus) {
          lastDepStatus =
            dataForStatusChange.deposit[depositDate].lastStatus.status;
          const lastStatus =
            dataForStatusChange.deposit[depositDate].lastStatus.status;
          const totalTime =
            dataForStatusChange.deposit[depositDate].down +
            dataForStatusChange.deposit[depositDate].up;
          const remainingTimeForDay = 24 * 60 - totalTime;
          dataForStatusChange.deposit[depositDate][lastStatus] +=
            remainingTimeForDay;
        } else {
          dataForStatusChange.deposit[depositDate][lastDepStatus] = 24 * 60;
        }

        depositSortedData.series[0].data.push(
          dataForStatusChange.deposit[depositDate].up
        );
        depositSortedData.series[1].data.push(
          dataForStatusChange.deposit[depositDate].down
        );
        depositSortedData.categories.push(depositDate);
      });

      const depTotalUptime = depositSortedData.series[0].data.reduce(
        (acc, val) => acc + val,
        0
      );

      const depTotalMinutes = depositSortedData.categories.length * 1440;
      setDepositStatusChart((prevData) => {
        return {
          ...prevData,
          series: depositSortedData.series,
          options: {
            ...prevData.options,
            title: {
              text: "Deposits",
            },
            subtitle: {
              text: `${((depTotalUptime / depTotalMinutes) * 100).toFixed(
                0
              )}% Uptime`,
            },
            xaxis: {
              ...prevData.options.xaxis,
              categories: depositSortedData.categories,
            },
          },
        };
      });
      Object.keys(dataForStatusChange.withdrawal).forEach((withdrawalDate) => {
        if (dataForStatusChange.withdrawal[withdrawalDate].lastStatus) {
          lastWithStatus =
            dataForStatusChange.withdrawal[withdrawalDate].lastStatus.status;
          const lastStatus =
            dataForStatusChange.withdrawal[withdrawalDate].lastStatus.status;
          const totalTime =
            dataForStatusChange.withdrawal[withdrawalDate].down +
            dataForStatusChange.withdrawal[withdrawalDate].up;
          const remainingTimeForDay = 24 * 60 - totalTime;
          dataForStatusChange.withdrawal[withdrawalDate][lastStatus] +=
            remainingTimeForDay;
        } else {
          dataForStatusChange.withdrawal[withdrawalDate][lastWithStatus] =
            24 * 60;
        }
        withdrawalSortedData.series[0].data.push(
          dataForStatusChange.withdrawal[withdrawalDate].up
        );
        withdrawalSortedData.series[1].data.push(
          dataForStatusChange.withdrawal[withdrawalDate].down
        );
        withdrawalSortedData.categories.push(withdrawalDate);
      });

      const withTotalUptime = withdrawalSortedData.series[0].data.reduce(
        (acc, val) => acc + val,
        0
      );
      const withTotalMinutes = withdrawalSortedData.categories.length * 1440;
      setWithdrawalStatusChart((prevData) => {
        return {
          ...prevData,
          series: withdrawalSortedData.series,
          options: {
            ...prevData.options,
            title: {
              text: "Withdrawals",
            },
            subtitle: {
              text: `${((withTotalUptime / withTotalMinutes) * 100).toFixed(
                0
              )}% Uptime`,
            },
            xaxis: {
              ...prevData.options.xaxis,
              categories: withdrawalSortedData.categories,
            },
          },
        };
      });
    }
  }, [entity]);

  return (
    <Grid container spacing={3}>
      {/* GENERAL CARD */}
      <Grid item xs={4}>
        <CustomCard cardHeader={"General"}>
          <Grid container>
            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Base volume
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {entity && getLatestVolume(entity, "base")}
            </Grid>

            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Target volume
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {entity && getLatestVolume(entity, "target")}
            </Grid>

            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Trust score
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {entity && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box
                    component="span"
                    bgcolor={
                      entity.trustScore
                        ? colorGrid[entity.trustScore].background
                        : colorGrid["gray"].background
                    }
                    color={
                      entity.trustScore
                        ? colorGrid[entity.trustScore].text
                        : colorGrid["gray"].text
                    }
                    borderRadius="4px"
                    height="24px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={0.5}
                    py={0.2}
                    fontSize={".75rem"}
                    fontWeight={700}
                  >
                    {exchangeInfo &&
                    exchangeInfo.trust_score !== undefined &&
                    exchangeInfo.trust_score !== null ? (
                      exchangeInfo.trust_score
                    ) : entity && entity.trustScore ? (
                      entity.trustScore
                    ) : (
                      <>No Data</>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Country
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {exchangeInfo && exchangeInfo.country
                ? exchangeInfo.country
                : "No information"}
            </Grid>

            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Trading pairs
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {entity && (
                <TruncateWords
                  words={getTradingPairs(entity)}
                  separator={" | "}
                  displayCount={4}
                  sx={{
                    fontSize: "0.85rem",
                    fontFamily: "Public Sans, sans-serif",
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>

      {/* INTEGRATION CARD */}

      <Grid item xs={4}>
        <CustomCard
          cardHeader={
            <Grid container>
              <Grid item xs={6}>
                Integration
              </Grid>
              <Grid item xs={6}>
                {entity && (
                  <Box display="flex" height="100%" justifyContent={"end"}>
                    {userData.isAdmin && (
                      <CustomButton
                        type={"edit"}
                        buttonText={"Edit"}
                        onClick={() => handleEdit("integration")}
                        sx={{
                          borderRadius: "4px",
                          px: 0.5,
                          py: 0.2,
                          fontSize: "0.675rem",
                          height: "24px",
                        }}
                      />
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          }
        >
          <Grid container gap={1}>
            <Grid item xs={5} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Native assets
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {entity?.hasNativeAssets ? "Yes" : "No"}
            </Grid>
            <Grid item xs={5} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Deposit status
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {entity && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box
                    component="span"
                    bgcolor={
                      currentDepositStatus === "No Data"
                        ? colorGrid["gray"].background
                        : currentDepositStatus === "Online"
                        ? colorGrid["green"].background
                        : colorGrid["red"].background
                    }
                    color={
                      currentDepositStatus === "No Data"
                        ? colorGrid["gray"].text
                        : currentDepositStatus === "Online"
                        ? colorGrid["green"].text
                        : colorGrid["red"].text
                    }
                    borderRadius="4px"
                    height="24px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={0.5}
                    py={0.2}
                    fontSize={".75rem"}
                    fontWeight={700}
                  >
                    {currentDepositStatus}

                    {currentFailScore > 0 && (
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={
                          <Box
                            sx={{
                              fontSize: "1rem",
                            }}
                          >
                            {currentFailScore.toFixed(1)}
                          </Box>
                        }
                      >
                        <WarningAmberIcon
                          sx={{
                            cursor: "pointer",
                            ml: "5%",
                            fontSize: ".75rem",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs={5} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Withdrawal status
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {entity && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box
                    component="span"
                    bgcolor={
                      currentWithdrawalStatus === "No Data"
                        ? colorGrid["gray"].background
                        : currentWithdrawalStatus === "Online"
                        ? colorGrid["green"].background
                        : colorGrid["red"].background
                    }
                    color={
                      currentWithdrawalStatus === "No Data"
                        ? colorGrid["gray"].text
                        : currentWithdrawalStatus === "Online"
                        ? colorGrid["green"].text
                        : colorGrid["red"].text
                    }
                    borderRadius="4px"
                    height="24px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={0.5}
                    py={0.2}
                    fontSize={".75rem"}
                    fontWeight={700}
                  >
                    {currentWithdrawalStatus}
                    {currentFailScore > 0 && (
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={
                          <Box
                            sx={{
                              fontSize: "1rem",
                            }}
                          >
                            {currentFailScore.toFixed(1)}
                          </Box>
                        }
                      >
                        <WarningAmberIcon
                          sx={{
                            cursor: "pointer",
                            ml: "5%",
                            fontSize: ".75rem",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs={5} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Type
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {entity && "Exchange"}
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>

      {/* SUPPORT CARD */}
      <Grid item xs={4}>
        <CustomCard cardHeader={"Support"}>
          <Grid container>
            {/* <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Networks
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {entity && "Mainnet | Preprod"}
            </Grid>
            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Components
              </Typography>
            </Grid>
            {entity &&
              ("cardano-node | rosetta | graphql".length > 20 ? (
                <Tooltip
                  title={
                    <h2 style={{ color: "lightblue" }}>
                      {"cardano-node | rosetta | graphql"}
                    </h2>
                  }
                >
                  <span
                    style={{ cursor: "pointer" }}
                  >{`${"cardano-node | rosetta | graphql".slice(
                    0,
                    25
                  )}...`}</span>
                </Tooltip>
              ) : (
                <span>{"cardano-node | rosetta | graphql"}</span>
              ))} */}

            <Grid item xs={6} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                }}
              >
                Centralized
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {entity && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box
                    component="span"
                    bgcolor={
                      entity.isCentralized
                        ? colorGrid["blue"].background
                        : colorGrid["gray"].background
                    }
                    color={
                      entity.isCentralized
                        ? colorGrid["blue"].text
                        : colorGrid["gray"].text
                    }
                    borderRadius="4px"
                    height="24px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={0.5}
                    py={0.2}
                    fontSize={".75rem"}
                    fontWeight={700}
                  >
                    {entity.isCentralized !== (undefined || null) ? (
                      <>{entity.isCentralized ? "Yes" : "No"}</>
                    ) : (
                      "No Data"
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>
      {entity?.exchangeStatus?.statusChanges.length > 0 && (
        <Grid item xs={12}>
          <CustomCard
            sx={{
              height: "300px",
            }}
            cardContentSx={{
              height: "100%",
              paddingBottom: "0px!important",
            }}
          >
            <ReactApexChart
              options={depositStatusChart.options}
              series={depositStatusChart.series}
              type="bar"
              height={"50%"}
            />
            <ReactApexChart
              options={withdrawalStatusChart.options}
              series={withdrawalStatusChart.series}
              type="bar"
              height={"50%"}
            />
          </CustomCard>
        </Grid>
      )}

      <Grid item xs={12}>
        <CustomCard sx={cardContentStyle}>
          <Box sx={cardContentStyle}>
            {dataLineChart && (
              <LineChart
                data={dataLineChart}
                yTitle={"Volume"}
                xTitle={"Time"}
              />
            )}
          </Box>
        </CustomCard>
      </Grid>
    </Grid>
  );
};

export default ExchangeDetails;
