import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CompareArrowsRoundedIcon from "@mui/icons-material/CompareArrowsRounded";
import ConnectedTvOutlinedIcon from "@mui/icons-material/ConnectedTvOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PaidIcon from "@mui/icons-material/Paid";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import { useUserData } from "../../user";

const Item = ({ title, to, icon, disabled, isCollapsed }) => {
  const location = useLocation();

  const isActive = location.pathname === to;

  const handleClick = () => {
    if (!disabled) {
    }
  };

  return (
    <MenuItem
      active={isActive}
      style={{
        color: isActive ? "#57648a" : "#8b97a1",
        backgroundColor: isActive ? "#e1eaf2" : "#fcfcfc",
        borderRadius: "7px",
        width: "80%",
      }}
      onClick={handleClick}
      icon={icon}
    >
      {!isCollapsed && <Typography>{title}</Typography>}
      {!disabled && <Link to={to} />}
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const user = useUserData();

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `#fcfcfc !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
          margin: "0px !important",
        },
        "& .pro-inner-item": {
          padding: "5px 20px 5px 20px !important",
          display: "flex",
          flexDirection: isCollapsed ? "column" : "row",
        },
        "& .pro-inner-item:hover": {
          backgroundColor: "#e1eaf2",
          borderRadius: "7px",
          color: "#57648a !important",
        },
        "& .titleItem > .pro-inner-item": {
          backgroundColor: "#fcfcfc",
          borderRadius: "7px",
          color: `#747474 !important`,
        },
        "& .titleItem > .pro-inner-item:hover": {
          backgroundColor: "#fcfcfc",
          borderRadius: "7px",
          color: `#747474 !important`,
        },
        "& .pro-inner-item.active": {
          color: "black !important",
        },
        borderRight: "1px solid #e3e5e9",
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            className="titleItem"
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "4px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                ml={"15px"}
              >
                <Typography variant="h5" color={colors.grey[100]}>
                  CHALMUNS CANTINA
                </Typography>
                <IconButton
                  sx={{
                    borderRadius: "7px",
                  }}
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb={"25px"}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={
                    user?.profile.profileImageIPFS
                      ? `https://ipfs.io/ipfs/${user?.profile.profileImageIPFS}`
                      : `${process.env.PUBLIC_URL}/images/cardano-logo-dark.png`
                  }
                  alt="Cardano"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box textAlign={"center"}>
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight={"bold"}
                  sx={{
                    m: "15px 0 0 0",
                  }}
                >
                  {user.displayName}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Item
              title="Dashboard"
              to="/dashboard"
              isCollapsed={isCollapsed}
              icon={<SpaceDashboardOutlinedIcon />}
            />
            <Item
              title="Entities"
              to="/entities"
              isCollapsed={isCollapsed}
              icon={<CompareArrowsRoundedIcon />}
            />
            <Item
              title="Native Assets"
              to="/cnts"
              isCollapsed={isCollapsed}
              icon={<PaidIcon />}
            />
            <Item
              title="Reporting"
              to="/reporting"
              isCollapsed={isCollapsed}
              icon={<SummarizeOutlinedIcon />}
            />
            <Item
              title="HardForks"
              to="/hardforks"
              isCollapsed={isCollapsed}
              icon={<UpdateRoundedIcon />}
            />
            <Item
              title="Announcements"
              to="/announcements"
              isCollapsed={isCollapsed}
              icon={<CampaignOutlinedIcon />}
            />
            {user.isAdmin && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{
                    padding: isCollapsed
                      ? "5px 20px 5px 20px !important"
                      : "5px 50px 5px 50px !important",
                    width: "100%",
                  }}
                >
                  Admin
                </Typography>
                <Item
                  title="Users"
                  isCollapsed={isCollapsed}
                  to="/admin/users"
                  icon={<GroupOutlinedIcon />}
                ></Item>
                <Item
                  title="Remote Channels"
                  to="/admin/channels"
                  isCollapsed={isCollapsed}
                  icon={<ConnectedTvOutlinedIcon />}
                ></Item>
                <Item
                  title="Partners"
                  isCollapsed={isCollapsed}
                  to="/admin/partners"
                  icon={<HandshakeIcon />}
                ></Item>
              </>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
