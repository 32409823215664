import { Box, Card, CardContent, Grid } from "@mui/material";
import LineChart from "../global/LineChart";
import PieChart from "../global/PieChart";

const cardContentStyle = {
  height: "400px",
};

const paperStyle = {
  boxShadow:
    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
  borderRadius: "16px",
};

const CNTProfile = ({ dataLine, dataPie, totalVolume }) => {
  return (
    <Card elevation={0} sx={paperStyle}>
      <CardContent
        sx={{
          pt: 0,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} lg={8}>
            <Box sx={cardContentStyle}>
              <LineChart data={dataLine} yTitle={"Volume"} xTitle={"Time"} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={cardContentStyle}>
              <PieChart data={dataPie} totalVolume={totalVolume} />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CNTProfile;
