import { Box, Container, Grid } from "@mui/material";

import { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import { Loading } from "../global/Loading";
import CNTSTable from "./CNTSTable";

const CNTS = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetch(`${backendUrl}/assets/top`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAssets(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Header title="Native assets" subtitle="Managing native assets" />
            </Grid>

            <Grid item xs={12}>
              {assets.length > 0 ? (
                <CNTSTable initialAssets={assets} />
              ) : (
                <Box
                  sx={{
                    padding: 4,
                    textAlign: "center",
                    color: "text.secondary",
                  }}
                >
                  No assets found.
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default CNTS;
