function getLatestVolume(exchange, type) {
  return (exchange[`${type}Volumes`][0]?.volume | 0).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function getTradingPairs(exchange) {
  let assetNames = exchange.assets.map((asset) => {
    let assetName = asset.name;
    if (asset.name.includes("_UNKNOWN")) {
      assetName = asset.name.split("_")[0];
    }
    return assetName;
  });
  return assetNames;
}

function prependZero(number) {
  return number < 10 ? "0" + number : number;
}

function formatDateForChart(date) {
  return `${prependZero(date.getDate())}-${prependZero(
    date.getMonth() + 1
  )}-${date.getFullYear()} ${prependZero(date.getHours())}:${prependZero(
    date.getMinutes()
  )}`;
}

function formatAndSortVolumesForLineChart(exchange, type = undefined) {
  if (type) {
    return exchange[`${type}Volumes`]
      .map((volume) => {
        const date = new Date(volume.timestamp);
        return {
          x: date,
          y: volume.volume,
          formattedDate: formatDateForChart(date),
        };
      })
      .reverse();
  } else {
    return exchange["volumes"]
      .map((volume) => {
        const date = new Date(volume.timestamp);
        return {
          x: date,
          y: volume.volume,
          formattedDate: formatDateForChart(date),
        };
      })
      .reverse();
  }
}

export { formatAndSortVolumesForLineChart, getLatestVolume, getTradingPairs };
