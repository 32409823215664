import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ExchangeStatusProvider } from "./utils/contexts/entities/exchanges/exchangeStatusContext";

import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ExchangeStatusAlert from "./components/ExchangeStatusAlert";
import Footer from "./components/Footer";
import UserActivityTracker from "./components/UserActivityTracker";
import AdminChannels from "./scenes/admin/channels";
import AdminPartners from "./scenes/admin/partners";
import AdminUsers from "./scenes/admin/users";
import Announcements from "./scenes/announcements";
import CNTS from "./scenes/cnts";
import CNT from "./scenes/cnts/CNT";
import Dashboard from "./scenes/dashboard";
import Entities from "./scenes/entities";
import Entity from "./scenes/entities/Entity";
import { Loading } from "./scenes/global/Loading";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import Hardforks from "./scenes/hardforks";
import Hardfork from "./scenes/hardforks/Hardfork";
import Login from "./scenes/login";
import Profile from "./scenes/profile";
import Reporting from "./scenes/reporting";
import ReportingComponent from "./scenes/reporting/ReportingComponent";
import SearchPage from "./scenes/search";
import { ColorModeContext, useMode } from "./theme";
import { useSetUserData, useTriggerUserUpdate, useUserData } from "./user";
import { UserActivityProvider } from "./utils/contexts/users/userActivityContext";
import { useLocalState } from "./utils/hooks/global/useLocalStorage";
import { useSessionState } from "./utils/hooks/global/useSessionStorage";

function App() {
  const [theme, colorMode] = useMode();
  const navigate = useNavigate();
  const [jwt, setJwt] = useLocalState(null, "jwt");
  const [storedUrl] = useSessionState("/dashboard", "url");
  const setUserData = useSetUserData();
  const userData = useUserData();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const location = useLocation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const triggerUserUpdate = useTriggerUserUpdate();

  useEffect(() => {
    const getJWT = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const jwt = urlParams.get("jwt");
      if (jwt) {
        setJwt(jwt);
        navigate(storedUrl);
      }
    };
    const getUser = () => {
      if (jwt) {
        fetch(`${backendUrl}/auth/google/getUserInfo`, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwt,
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            throw new Error("Authentication failed");
          })
          .then((resObject) => {
            setUserData(resObject);
            console.log("Setting user");
            setTimeout(() => {
              setLoading(false);
            }, 500);
          })
          .catch((err) => {
            console.log(err);
            setError(true);
            navigate(storedUrl);
            setTimeout(() => {
              setLoading(false);
            }, 500);
          });
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    };
    getJWT();
    getUser();
    // eslint-disable-next-line
  }, [jwt, backendUrl, triggerUserUpdate]);

  return (
    <ExchangeStatusProvider jwt={jwt}>
      <UserActivityProvider jwt={jwt}>
        <UserActivityTracker />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <ExchangeStatusAlert />
              <CssBaseline />

              <div className="app">
                {userData && <Sidebar />}
                <main
                  className="content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                  }}
                >
                  <div
                    className="main-content"
                    style={{
                      flex: "1",
                    }}
                  >
                    {userData && <Topbar />}

                    {loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "75vh",
                        }}
                      >
                        <Loading size={50} thickness={6} />
                      </Box>
                    ) : (
                      <TransitionGroup>
                        <CSSTransition
                          key={location.pathname}
                          classNames="page"
                          timeout={300}
                          unmountOnExit
                        >
                          <div className="page-content">
                            <Routes location={location}>
                              <Route
                                path="/login"
                                element={
                                  !error ? (
                                    <Navigate to={"/dashboard"} />
                                  ) : (
                                    <Login />
                                  )
                                }
                              />
                              <Route
                                path="/"
                                element={
                                  !error ? (
                                    <Navigate to={"/dashboard"} />
                                  ) : (
                                    <Login />
                                  )
                                }
                              />
                              <Route
                                path="/profile"
                                element={userData ? <Profile /> : <Login />}
                              />
                              <Route
                                path="/dashboard"
                                element={userData ? <Dashboard /> : <Login />}
                              />
                              <Route
                                path="/entities"
                                element={userData ? <Entities /> : <Login />}
                              />
                              <Route
                                path="/cnts"
                                element={userData ? <CNTS /> : <Login />}
                              />
                              <Route
                                path="/entities/:slug"
                                element={userData ? <Entity /> : <Login />}
                              />
                              <Route
                                path="/cnts/:coinId"
                                element={userData ? <CNT /> : <Login />}
                              />
                              <Route
                                path="/reporting"
                                element={userData ? <Reporting /> : <Login />}
                              />
                              <Route
                                path="/reporting/:componentTypeSlug"
                                element={
                                  userData ? <ReportingComponent /> : <Login />
                                }
                              />
                              <Route
                                path="/hardforks"
                                element={userData ? <Hardforks /> : <Login />}
                              />
                              <Route
                                path="/announcements"
                                element={
                                  userData ? <Announcements /> : <Login />
                                }
                              />
                              <Route
                                path="/hardforks/:hardforkSlug"
                                element={userData ? <Hardfork /> : <Login />}
                              />
                              <Route
                                path="/search"
                                element={userData ? <SearchPage /> : <Login />}
                              />
                              <Route
                                path="/admin/users"
                                element={
                                  userData && userData.isAdmin ? (
                                    <AdminUsers />
                                  ) : (
                                    <Navigate to="/dashboard" />
                                  )
                                }
                              />
                              <Route
                                path="/admin/channels"
                                element={
                                  userData && userData.isAdmin ? (
                                    <AdminChannels />
                                  ) : (
                                    <Navigate to="/dashboard" />
                                  )
                                }
                              />
                              <Route
                                path="/admin/partners"
                                element={
                                  userData && userData.isAdmin ? (
                                    <AdminPartners />
                                  ) : (
                                    <Navigate to="/dashboard" />
                                  )
                                }
                              />
                            </Routes>
                          </div>
                        </CSSTransition>
                      </TransitionGroup>
                    )}
                  </div>
                  <Footer />
                </main>
              </div>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </LocalizationProvider>
      </UserActivityProvider>
    </ExchangeStatusProvider>
  );
}

export default App;
