import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { chartColors } from "../../theme";
import { formatAndSortVolumesForLineChart } from "../../utils/entity/entity";
import { fillEmptyDates } from "../../utils/graphs/graphs";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import { Loading } from "../global/Loading";
import CNTExchangesTable from "./CNTExchangesTable";
import CNTProfile from "./CNTProfile";

function sortByVolume(exchanges) {
  return [...exchanges].sort((a, b) => {
    const lastVolumeA = a.volumes[a.volumes.length - 1]?.volume || 0;
    const lastVolumeB = b.volumes[b.volumes.length - 1]?.volume || 0;
    return lastVolumeB - lastVolumeA;
  });
}

function sortByExchanges(volumes) {
  const sortedVolumes = {};

  volumes.forEach((volume) => {
    const volumeData = {
      volume: volume.volume,
      timestamp: volume.timestamp,
    };
    if (!sortedVolumes[volume.exchange.slug]) {
      sortedVolumes[volume.exchange.slug] = {
        ...volume.exchange,
        volumes: [volumeData],
      };
    } else {
      sortedVolumes[volume.exchange.slug]["volumes"].push(volumeData);
    }
  });

  return sortByVolume(Object.values(sortedVolumes));
}

function getVolume(exchange) {
  const volumes = exchange["volumes"];
  return volumes[0] ? volumes[0].volume : 0;
}

function filterTopExchanges(top, exchanges) {
  console.log(exchanges);
  return exchanges.slice(0, top);
}

function calculateTotalVolumes(exchanges) {
  return {
    totalVolume: exchanges.reduce(
      (accumulator, exchange) => accumulator + getVolume(exchange),
      0
    ),
  };
}

function createChartData(topExchanges, totalVolume) {
  const chartData = {
    lineChart: [],
    pieChart: [],
    topVolumes: 0,
  };

  for (let i = 0; i < topExchanges.length; i++) {
    const exchange = topExchanges[i];

    const data = formatAndSortVolumesForLineChart(exchange);
    const volume = getVolume(topExchanges[i]);

    if (volume !== 0) {
      chartData["lineChart"].push({
        id: exchange.slug,
        data: data.map((dataItem) => ({
          y: dataItem.y,
          x: dataItem.formattedDate,
        })),
        visible: i < 5,
        color: chartColors[i],
        name: exchange.name,
      });

      chartData["pieChart"].push({
        id: exchange.slug,
        label: exchange.name,
        value: volume,
        color: chartColors[i],
      });

      chartData.topVolumes += volume;
    }
  }

  const otherVolume = (totalVolume.totalVolume - chartData.topVolumes).toFixed(
    2
  );

  if (otherVolume > 0) {
    chartData["pieChart"].push({
      id: "other",
      label: "Other",
      value: otherVolume,
      color: chartColors[10],
    });
  }

  chartData.lineChart = fillEmptyDates(chartData.lineChart);
  return chartData;
}

function CNT() {
  const { coinId } = useParams();
  const [jwt] = useLocalState(null, "jwt");
  const [asset, setAsset] = useState();
  const [dataLine, setDataLine] = useState([]);
  const [dataPie, setDataPie] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [totalVolume, setTotalVolume] = useState(0);
  const [loading, setLoading] = useState(true);
  const [tabsValue, setTabsValue] = useState("profile");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetch(`${backendUrl}/assets/${coinId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Entity not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setAsset(data);
        setDataForCharts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoading(false);
      });
  }, [jwt, coinId, backendUrl]);

  function setDataForCharts(data) {
    if (Array.isArray(data?.assetVolumes)) {
      const volumesSortedByExchanges = sortByExchanges(data?.assetVolumes);
      setDataTable(volumesSortedByExchanges);

      const topExchanges = filterTopExchanges(10, volumesSortedByExchanges);

      const totalVolumes = calculateTotalVolumes(volumesSortedByExchanges);
      setTotalVolume(totalVolumes.totalVolume);

      const chartData = createChartData(topExchanges, totalVolumes);
      setDataLine(chartData.lineChart);
      setDataPie(chartData.pieChart);
    }
  }

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container>
            {asset && (
              <>
                <Grid item xs={6}>
                  <Header
                    title={
                      <>
                        <img
                          src={asset?.logo}
                          alt="logo"
                          style={{
                            width: "25px",
                            height: "25px",
                            marginRight: "10px",
                          }}
                        />
                        {asset?.name}
                      </>
                    }
                    subtitle={`Native Asset Profile`}
                  />
                </Grid>

                <Grid container spacing={3} mt={"5px"}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Tabs
                      value={tabsValue}
                      onChange={handleChange}
                      aria-label="secondary tabs example"
                      sx={{
                        paddingLeft: "20px",
                      }}
                      variant="scrollable"
                    >
                      <Tab disableRipple value="profile" label="Profile" />
                      <Tab disableRipple value="exchanges" label="Exchanges" />
                    </Tabs>
                  </Grid>
                  <Grid item xs={12}>
                    {asset && (
                      <>
                        {tabsValue === "profile" && (
                          <>
                            <CNTProfile
                              dataLine={dataLine}
                              dataPie={dataPie}
                              totalVolume={totalVolume}
                            />
                          </>
                        )}
                        {tabsValue === "exchanges" && (
                          <>
                            <CNTExchangesTable dataTable={dataTable} />
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      )}
    </>
  );
}

export default CNT;
